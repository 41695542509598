import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Hooks/TypedHooks';
import { socketioClient, socketIoConnect } from '../../Helpers/SocketService';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import parse from 'html-react-parser';
import CameraCheckComponent from '../CameraCheck/CameraCheckComponent';
import axios from 'axios';
import { Piece } from '../../Types/PieceTypes';
import { getConfig } from '../../Helpers/ConfigService';
import { getTokenFromStorage, performLogout } from '../../Helpers/AuthService';
import { setWorkstation } from '../../Store/WorkstationService';
import WasteCreatorComponent from '../WasteCreator/WasteCreatorComponent';
import { Waste } from '../../Types/WasteTypes';
import { createWaste } from '../../Helpers/WasteService';
import { createPiece, updatePiece } from '../../Helpers/PieceService';

const DashboardMainComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 
    const workstation = useAppSelector((state) => state.workstation.value);
    const id = "12345"
    const [isConnected, setIsConnected] = useState(false);
    const [isInstructionRead, setIsInstructionRead] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [piece, setPiece] = useState<Piece | null>(null);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        console.log("Socket connect to: ", getConfig().socket_service);
        socketIoConnect(getConfig().socket_service);        
        socketioClient.on('connect', () => {
            setIsConnected(true);
            console.log("conn event");                
        });
        socketioClient.on('disconnect', () => {
            setIsConnected(false);
            console.log("dc event");
        });

        socketioClient.on("connect_error", (err:any) => {
            console.log(`connect_error due to ${err.message}`);
        });

        socketioClient.on("wsConnAck", (data:any) => {
            console.log("wsConnAck:", data);
            dispatch(setWorkstation(data.data));
        })

        socketioClient.on("serverEvent", (data: string) => {
            console.log(data)
            socketioClient.emit("wsConn", {id});
        });

        return () => {            
            socketioClient.off('connect');
            socketioClient.off('disconnect');
            socketioClient.off('wsConnAck');
            socketioClient.off('connect_error');
            socketioClient.off('serverEvent');                      
        };
    }, []);

    useEffect(() => {
        if(workstation.actualProduct && workstation.actualProduct.instruction) {
            setShowInstructions(true);
        }
    }, [workstation]);

   
    const handleLogout = () => {    
        performLogout();
        navigate("/login", {replace: true});
    }

    const handleChange = (index: number) => {
        if(workstation.actualProduct?.instruction.length === index +1) {
            setIsInstructionRead(true);
        }
    };

    const startManufacture = () => {
        setShowInstructions(false);
        setIsStarted(true);

        createPiece({
            id: undefined,
            start: new Date(),
            qualityCheck: "unknown",
            product: workstation.actualProduct ?  workstation.actualProduct : undefined,
            workstation: workstation,
        }).then(res => {
            setPiece(res);
        }).catch(err => console.error(err));
    };

    const stopManufacture = () => {
        setIsStarted(false);
    };

    const wastePrepared = (w: Waste) => {
        createWaste(w)
            .then((r) => createPiece({
                id: undefined,
                start: new Date(),
                qualityCheck: "unknown",
                product: workstation.actualProduct ?  workstation.actualProduct : undefined,
                workstation: workstation,
            }).then(res => {
                setPiece(res);
            }).catch(error => {
                console.log(error);           
            }))
        .catch((err) => console.error(err));
        setIsChecked(false);
    };

    const acceptPiece = () => {
        setPiece({ ...piece, end: new Date(), qualityCheck: "ok"} as Piece);
        updatePiece(piece as Piece).then((r) => createPiece({
            id: undefined,
            start: new Date(),
            qualityCheck: "unknown",
            product: workstation.actualProduct ?  workstation.actualProduct : undefined,
            workstation: workstation,
        }).then(res => {
            setPiece(res);
        }).catch(error => {
            console.log(error);           
        }));
        setIsChecked(false);
    }

    return (
        <div className="App">
            <div className='button-container'>
                <div>
                    {(isInstructionRead && !isStarted) && <button className='btn btn-primary' onClick={startManufacture}>Gyártás indítása</button>}     
                </div> 
                {!isStarted && <button className='btn btn-secondary' onClick={handleLogout}>Kijelentkezés</button>}
            </div>
              
            {showInstructions &&
            <Carousel showThumbs={false} onChange={handleChange}>
                {workstation.actualProduct?.instruction.map((instruction, index) =>
                <div key={index}>
                    <div>{parse(instruction.description)}</div>
                    <p className="legend">{instruction.name}</p>
                </div>)}              
            </Carousel>}
            {
                isStarted && workstation !== null && piece !== null &&
                <div>
                    <p>Aktuális darab azonosítója: {piece.id}</p>
                    <CameraCheckComponent
                        workstation={workstation}
                        piece={piece}
                        setIsChecked={setIsChecked}
                    />
                    <span>
                        <button
                        className={`btn ${isChecked ? " btn-primary " : "btn-secondary"}`}
                        onClick={acceptPiece}
                        >
                            Darab elfogadása
                        </button>
                        { !isChecked &&
                          <span style={{"marginLeft": "5px"}}>Elfogadás előtt kamerás ellenőrzés szükséges!</span>
                        }
                    </span>
                    <WasteCreatorComponent
                        workstation={workstation}
                        piece={piece}
                        createWaste={wastePrepared}
                    />
                    {isStarted && <button className='btn btn-secondary' onClick={stopManufacture}>Gyártás leállítása</button>}
                </div>
            }            
        </div>
    );
}

export default DashboardMainComponent;