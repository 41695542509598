import { ConfigType } from "../Types/CommonTypes";

let config: ConfigType = {
    socket_service: "",
    token_service: "",
    api_url: "",
};

export const fetchConfig = () => {
    return new Promise(async (resolve, reject) => {
        fetch('config.json')
            .then(res => res.json())
            .then(res => {
                config = {
                    socket_service: res.socket_service,
                    token_service: res.token_service,
                    api_url: res.api_url,
                };
                return resolve(config);
            })
            .catch(err => reject(err));
    });
};

export const getConfig = () => config;

