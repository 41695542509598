import jwtDecode from 'jwt-decode';
import { User, initialUser } from "../Types/UserTypes";
import axios from 'axios';
import { getStore } from '../Store/Store';
import { login, logout, updateToken } from '../Store/UserService';
import { getConfig } from './ConfigService';

export const performLogin = async (cardId: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        const store = getStore();
        let token = getTokenFromStorage();
        const config = getConfig();
        if (!token) {
            token = await getTokenFromServer(cardId, config.token_service);
            saveToken(token);        
        }            
        const user = token ? parseToken(token) : initialUser;
        store.dispatch(updateToken(token));
        store.dispatch(login(user));
        return resolve(user.sub ? true : false);
    });
};

export const getTokenFromStorage = () => {
    return localStorage.getItem('token');
};

export const removeTokenFromStorage = () => {
    localStorage.removeItem('token');
};

const parseToken = (token: string): User => {
    const tokenData = decodeJwt(token);
    const user: User = {
        id: "",
        email: tokenData.email,
        firstName: tokenData.given_name,
        lastName: tokenData.family_name,
        groups: tokenData.groups,
        sub: tokenData.sub,
        token: token
    };
    return user;
};

const decodeJwt = (token: string | undefined): any => {
    if (token) {
        return jwtDecode(token);
    }
    return undefined;
};

const saveToken = (token: string | undefined) => {
    if (token) {
        localStorage.setItem('token', token);
    }       
};

export const performLogout = (): void => {
    removeTokenFromStorage();
    const store = getStore();
    store.dispatch(logout());
};

export const isAuthenticated = (): boolean => {
    const store = getStore();
    return store.getState().user.value.sub ? true : false;
};

const getTokenFromServer = async (cardId: string, url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        let token = "";
        axios.post(url,
            { "cardId": cardId }
        ).then(response => {
            console.log("Token", response)
            token = response.data.data;
            return resolve(token);
        }).catch(error => {
            console.log(error);
            return reject(error);        
        });
    });
}