import { useEffect, useState } from "react";
import { listWasteCategories } from "../../Helpers/WasteCategoryService";
import { Waste, WasteCategory } from "../../Types/WasteTypes";

const WasteCreatorkComponent = (props: any) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [description, setDescription] = useState("");
    const [wasteCategoryList, setWasteCategoryList] = useState<WasteCategory[]>([]);
    const [selectedWasteCategory, setSelectedWasteCategory] = useState<WasteCategory>({
        id: undefined,
        name: "",
    });

    const initModal = () => {
        setSelectedWasteCategory({
            id: undefined,
            name: "",
        });
        listWasteCategories()
            .then((res: any) => setWasteCategoryList(res.docs))
            .catch((err) => console.error(err));
    }

    useEffect(() => { 
        initModal()
    }, [])

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
        initModal();
    }

    const saveWaste = () => {
        if (selectedWasteCategory.id === undefined) {
            return
        }
        props.createWaste({
            id: undefined,
            wasteCategory: selectedWasteCategory,
            piece: props.piece,
            description,
        });
        closeModal();
    }
 
    return (
        <div className='button-container'>
            <button className='btn btn-danger' onClick={openModal}>
                Selejt jelentése
            </button>
            { modalOpen &&
                <div className='full-modal'>
                    <p>Válassza ki a selejt kategóriáját:</p>
                    {wasteCategoryList.map((b) => {
                        return <button 
                            onClick={() => setSelectedWasteCategory(b)}
                            className={`btn ${selectedWasteCategory.id === b.id ? " btn-primary " : "btn-secondary"}`}
                            style={{margin: "5px"}}
                            key={b.id}
                        >
                            {b.name}
                        </button>;
                    })}
                    <p>Szöveges megjegyzés:</p>
                    <textarea
                        style={{margin: "1%", width: "97%", height: "20%"}}
                        onChange={(evt) => setDescription(evt.target.value)}
                    ></textarea>
                    <div>
                        <button className={'btn btn-secondary'} style={{margin: "5px"}} onClick={closeModal}>
                            Bezár
                        </button>
                        <button
                            style={{margin: "5px"}} onClick={saveWaste}
                            className={`btn ${selectedWasteCategory.id === undefined ? " btn-secondary " : "btn-primary"}`}
                        >
                            Elment
                        </button>
                    </div>
                </div>
            }
        </div>
    )
};

export default WasteCreatorkComponent;