import axios from "axios";
import { CamApiRes } from "../Types/CamApiTypes";
import { getTokenFromStorage } from "./AuthService";
import { getConfig } from "./ConfigService";

export const checkPiece = (pieceId: string) => {
    return axios.get(
        getConfig().api_url +
        "/cam-api/check/" +
        pieceId,
        {
            headers: {
                authorization: "Kcattr " + getTokenFromStorage(),
            }
        }
    )
}

export const getResultImageUrl = (res: CamApiRes) => {
    if (!res) {
        return "/missingImage.png";
    }
    if (!res.id) {
        return "/missingImage.png";
    }
    return `
        ${getConfig().api_url}/cam-api/resimage/Kcattr${getTokenFromStorage()}/${res.id}
    `;
} 