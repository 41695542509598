import { createSlice } from "@reduxjs/toolkit";
import { initialWorkstation } from "../Types/WorkstationTypes";

const workstationSlice = createSlice({
    name: "workstation",
    initialState: {
        value: initialWorkstation
    },
    reducers: {
        setWorkstation: (state, action) => {
            state.value = action.payload;
        },
       
    }
});
export const { setWorkstation } = workstationSlice.actions;
export default workstationSlice.reducer;