import { createSlice } from "@reduxjs/toolkit";
import { initialUser } from "../Types/UserTypes";

const userSlice = createSlice({
    name: "user",
    initialState: {
        value: initialUser,
        token: "",
    },
    reducers: {
        updateToken: (state, action) => {
            state.token = action.payload;
        },
        login: (state, action) => {
            state.value = action.payload;
        },
        logout: (state) => {
            state.value = initialUser;
        }
    }
});

export const { login, logout, updateToken } = userSlice.actions;
export default userSlice.reducer;