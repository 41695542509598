import { configureStore } from '@reduxjs/toolkit';
import userReducer from './UserService';
import workstationReducer from './WorkstationService'
const store = configureStore({
    reducer: {
      user: userReducer,
      workstation: workstationReducer
    }
      
});
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;

export function getStore() {
  return store;
};

export function getUserState() {
  return store.getState().user.value
}