import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { performLogin } from "../../Helpers/AuthService";

const LoginComponent = () => {
    const [cardId, setCardId] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = () => {
        performLogin(cardId)
            .then((res) => setIsAuthenticated(res === true))
            .catch((err) => {
                console.error("Login error:", err);
            });
    }

    useEffect(() => {
        login();
    },[]);

    const submit = async (e: any) => {
        e.preventDefault();        
        login();
    };

    const handleChange = (e: any): void => {
        console.log("Changed cardid:", e.target.value);
        setCardId(e.target.value);
    };

    return (
        <div className="login-page">
            {isAuthenticated && <Navigate to="/" replace />}
            <div>
                <h1>A gyártás megkezdéséhez jelentkezzen be!</h1>
                <form onSubmit={submit}>
                    <input type="text" value={cardId} onChange={handleChange}/>
                </form> 
            </div>             
        </div>
    )
}
export default LoginComponent;