import { io } from "socket.io-client";
import { getStore } from "../Store/Store";

export let socketioClient: any = null;

export const socketIoConnect = (url: string) => {
    if (socketioClient === null) {
        socketioClient = io(url, {
            extraHeaders: {
                Authorization: `Kcattr ${getStore().getState().user.token}`
            }
        });
    }
}