import RouteList from './Routes/Routes';
import './App.css';
import { useEffect, useState } from 'react';
import { fetchConfig } from './Helpers/ConfigService';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchConfig()
      .then((conf) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Config load error:", err);
        setMessage("Konfigurációs fájl betöltése sikertelen!");
      });
  });

  return (
    <div className="container">
      { !isLoading && message.length === 0 &&
        <RouteList></RouteList>
      }
      {
        message.length > 0 &&
        <p>{message}</p>
      }
    </div>
  );
}

export default App;