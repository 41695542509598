import { useState } from 'react';
import { checkPiece, getResultImageUrl } from '../../Helpers/CamApiService';
import { CamApiRes } from '../../Types/CamApiTypes';

const CameraCheckComponent = (props: any) => {
    const [camApiRes, setCamApiRes] = useState<CamApiRes>();
    const [camApiError, setCamApiError] = useState<string>("");
    const [checkInProgress, setCheckInProgress] = useState(false);

    const cameraCheck = () => {
        setCheckInProgress(true);
        props.setIsChecked(true);
        checkPiece(props.piece.id).then(res => {
            if (res.data.data.message === "") {
                setCamApiRes(res.data.data);
                console.log(getResultImageUrl(camApiRes as CamApiRes));
            } else {
                console.log(res.data.data);
                setCamApiError(
                    "Külső kamera API nem elérhető a megadott url-en: " +
                    res.data.data.camApiReq.url,
                );
            }
        }).catch(error => {
            console.log("error:", error);
            setCamApiError(
                "Külső kamera API nem elérhető, a megadott URL érvénytelen!"
            );
        }).finally(() =>
            setCheckInProgress(false)
        );
    } 

    const closeResult = () => {
        setCamApiRes(undefined);
        setCamApiError("");
    }
    return (
        <div className='button-container'>
            <button className='btn btn-primary' onClick={cameraCheck}>
                Kamerás ellenőrzés indítása
            </button>
            { ((camApiRes || checkInProgress) || camApiError.length > 0) &&
                <div className='full-modal'>
                    { camApiRes &&
                        <div>
                            <p>Az ellenőrzés azonosítója: {camApiRes.id}</p>
                            <p>A darab elfogadott/nem elfogadott: {camApiRes.scriptResults[0].decision}</p>
                            <img className="check-img" src={getResultImageUrl(camApiRes)}/>
                        </div>
                    }
                    { !camApiRes && camApiError.length === 0 &&
                        <p>Betöltés...</p>
                    }
                    { camApiError.length > 0 &&
                        <p>Ellenőrzés sikertelen: {camApiError}</p>
                    }
                    <button className='btn btn-primary' onClick={closeResult}>
                        Bezár
                    </button>
                </div>
            }
        </div>
    )
};

export default CameraCheckComponent;