export type User = {
    id: String,
    email: String,
    firstName: String,
    lastName: String,
    groups: Array<String>,
    sub: String,
    token: string
};

export const initialUser: User = {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    groups: [],
    sub: "",
    token: ""
};

