import axios from "axios"
import { getStore } from "../Store/Store"
import { Piece } from "../Types/PieceTypes";
import { getConfig } from "./ConfigService"

export const createPiece = (k: Piece): Promise<Piece> => {
    return new Promise((resolve, reject) => {
        axios.post(
            getConfig().api_url +
            "/piece",
            {
                start: new Date(k.start).getTime(),
                qualityCheck: k.qualityCheck,
                product: k.product ? k.product.id : null,
                workstation: k.workstation ? k.workstation.id : null,
            },
            {
                headers: {
                    authorization: "Kcattr " + getStore().getState().user.token,
                }
            })
            .then((res) => resolve(res.data.data))
            .catch(err => reject(err));
    });
}

export const updatePiece = (k: Piece): Promise<Piece> => {
    return new Promise((resolve, reject) => {
        axios.put(
            getConfig().api_url +
            "/piece",
            {
                id: k.id,
                start: new Date(k.start).getTime(),
                end: k.end ? new Date(k.end).getTime() : null,
                qualityCheck: k.qualityCheck,
            },
            {
                headers: {
                    authorization: "Kcattr " + getStore().getState().user.token,
                }
            })
            .then((res) => resolve(res.data.data))
            .catch(err => reject(err));
    });
}