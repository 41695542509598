export type Product = {
    id: number | undefined,
    name: string,
    barcode: string,
    internalId: string,
    cycleTime: number,
    instruction: Array<ProductInstruction>,
    createdAt?: Date,
    createdBy?: string 
};

export const initialProduct: Product = {
    id: undefined,
    name: "",
    barcode: "",
    internalId: "",
    cycleTime: 0,
    instruction: [],
    createdAt: undefined,
    createdBy: undefined
};

export type ProductInstruction = {
    name: string,
    description: string,
    index: number
};

export const initialProductInstruction: ProductInstruction = {
    name: "",
    description: "",
    index:  0
};

export type instructionList = {
    instructions: Array<ProductInstruction>
    onDelete?: any,
    onModify?: any,
    onSort?: any
};