import axios from "axios"
import { getStore } from "../Store/Store"
import { Waste } from "../Types/WasteTypes";
import { getConfig } from "./ConfigService"

export const createWaste = (k: Waste) => {
    return new Promise((resolve, reject) => {
        axios.post(
            getConfig().api_url +
            "/waste",
            {
                description: k.description,
                wasteCategory: k.wasteCategory ? k.wasteCategory.id : null,
                piece: k.piece ? k.piece.id : null,
            },
            {
                headers: {
                    authorization: "Kcattr " + getStore().getState().user.token,
                }
            })
            .then((res) => resolve(res.data.data))
            .catch(err => reject(err));
    });
}