import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from '../Components/Auth/LoginComponent';
import DashboardMainComponent from '../Components/Dashboard/DashboardMainComponent';

const RouteList = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<DashboardMainComponent />} />
            </Route> 
            <Route path="login" element={<Login />}></Route>
        </Routes>
    );
};
export default RouteList;