import { initialProduct, Product } from "./ProductTypes";
export type Workstation = {
    id?: string,
    name: string,
    cameraApiUrl: string,
    actualProduct: Product,
    createdAt?: Date,
    searchText?: string
};

export const initialWorkstation: Workstation = {    
    name: "",
    cameraApiUrl: "",
    actualProduct: initialProduct,
};

export type WorkstationProps = {
    workstation: Workstation
};